<template>
  <div class="d-flex flex-column">
    <top-navbar></top-navbar>
    <router-view class="flex-fill"></router-view>
  </div>
</template>

<script>
  import TopNavbar from './TopNavbar.vue'

  export default {
    components: {
      TopNavbar
    }
  }

</script>
