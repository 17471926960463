<template>
  <div class="nw-nav nw-nav-border">
    <nav class="navbar navbar-expand-md navbar-light justify-content-between">
      <a class="navbar-brand d-none d-md-block" href="#/user/searchsleep"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#/user/searchsleep" class="navbar-brand d-md-none"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#" class="navbar-brand"></a>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" :class="$route.path=='/user/searchsleep' ? 'active' : ''" >
          <a class="nav-link" href="#/user/searchsleep">Activity</a>
        </li>
        <li class="nav-item" :class="$route.path=='/user/searchpatient' ? 'active' : ''" >
          <a class="nav-link" href="#/user/searchpatient">Search</a>
        </li>
        <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Recents
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a v-for="item in nc.recents" class="dropdown-item" href="#" @click="select(item.oid)">{{ item.name}} ({{ item.participantID}})</a>

        </div>
      </li>

      </ul>
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-cog"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#/user/searchsleep">
              <i class="fa fa-search"></i> Sleep Activity
            </a>
            <a class="dropdown-item" href="#/user/searchpatient">
              <i class="fa fa-search"></i> Search Patients
            </a>
            <a class="dropdown-item" href="#/user/participantbatch">
              <i class="fa fa-list"></i> Patient Batches
            </a>
            <a class="dropdown-item" href="#/user/addparticipant">
              <i class="fa fa-plus"></i> Add Patient
            </a>
            <!--<a class="dropdown-item" href="#/user/account">
              <i class="fa fa-user"></i> My Account
            </a>-->
            <a class="dropdown-item" href="#/admin/dashboard" v-show="nc.ctx.suRole == true || nc.ctx.adminRole == true">
              <i class="fa fa-cog"></i> Admin
            </a>
            <a href="#" class="dropdown-item text-danger" @click="logout()">
              <i class="fa fa-power-off"></i> Log Out
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  name: 'Home',
  data () {
      return {
        nc: NetClient,
        logo: process.env.VUE_APP_LOGO
      }
  },
  methods: {
    select (_oid) {
      NetClient.doRequest('rest/session', { action: 'selectPatient', patientID: _oid }).then(msg => {
        if('OK' == msg.retval) this.$router.push({ name: 'SleepSummary'})
      })
    },
    toggleLang : function(_lang)
      {
        this.$ml.change(_lang)
        //NetClient.lang = _lang
      },
      logout() {
        NetClient.logOut()
      }
  }
}
</script>
<style scoped>
.nw-nav {
  background-color: #FFFFFF;
}
</style>
